/* Resetting margin and setting global font */
body, html, #root {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #000; /* Ensures the background is always black */
  color: #fff; /* Ensures text color is white globally */
  height: 100%; /* Ensures full height coverage */
}

/* Font-smoothing for better text rendering */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Specific code block styling */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Ensure the root div has full height coverage */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the app takes up full screen height */
}
